import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { IMAGES } from "../constant/theme";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Description, Details } from "@mui/icons-material";
import { Button, Form, Modal } from "react-bootstrap";
import {
  addNotification,
  getNotifications,
  DeleteNotification,
  NotificationUSerList,
} from "../../store/actions/AdminAction";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

const validationSchema = Yup.object().shape({
  selectedUsers: Yup.string().required(
    <span style={{ color: "red" }}>Required</span>
  ),
  title: Yup.string().required(<span style={{ color: "red" }}>Required</span>),
  description: Yup.string().required(
    <span style={{ color: "red" }}>Required</span>
  ),
  // searchUsers: Yup.string().required(
  //   <span style={{ color: "red" }}>Required</span>
  // ),
});

const Notification = () => {
  const notificationData = useSelector(
    (state) => state?.get_notifications?.getnotifications
  );

  const [userSearch, setUserSearch] = useState("");
  const [showUserList, setShowUserList] = useState(true);

  const [showd, setshowd] = useState(false);
  const [searchUser, setShowSearchUser] = useState(false);
  const [notificationid, setNotificationid] = useState("");
  const [limit, setlimit] = useState(10);
  const [pagination, setpagination] = useState("");
  const [Id, setId] = useState("");
  const [UserList, setuserList] = useState("");
  console.log(UserList, "idididididididididid");
  const [page, setPage] = useState(1);
  const [flag, setflag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [slectedSearchUserId, setSlectedSearchUserId] = useState(null);
  const lastIndex = page * limit;
  const npage = Math.ceil(
    (pagination?.totalCount == undefined ? 0 : pagination?.totalCount) / limit
  );
  const number = npage ? [...Array(npage + 1).keys()]?.slice(1) : [1];
  console.log("slectedSearchUserId", slectedSearchUserId);
  function prePage() {
    if (page !== 1) {
      setPage(page - 1);
    }
  }
  function changeCPage(id) {
    setPage(id);
  }
  function nextPage() {
    if (page !== npage) {
      setPage(page + 1);
    }
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const getdata = async () => {
      try {
        const data = await dispatch(
          getNotifications({ limit: limit, page: page })
        );
        console.log(data);
        if (data?.status === 200) {
          setpagination(data?.data);
          setId(data.data.data[0]._id);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdata();
  }, [limit, page, flag]);

  console.log(UserList?._id, "UserList?._idUserList?._id");

  useEffect(() => {
    const getdata = async () => {
      try {
        if (searchUser && userSearch.trim() !== "") {
          const data = await dispatch(
            NotificationUSerList({ search: userSearch })
          );
          if (data?.status === 200) {
            setuserList(data?.data?.data);
            // setSlectedSearchUserId(null);
          }
        } else {
          setuserList([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdata();
  }, [userSearch, searchUser, dispatch]);

  const handlerNotificationD = async () => {
    try {
      const data = await dispatch(DeleteNotification({ id: notificationid }));
      console.log(data);
      if (data?.status == 200) {
        toast.success(data?.data?.message);
        setflag(!flag);
        setshowd(false);
      } else {
        toast.error(data?.data?.message);
      }
      setflag(!flag);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserSelect = (user) => {
    setUserSearch(user.name);
    setShowUserList(false);
    setSlectedSearchUserId(user._id);

  };

  return (
    <Layout>
      <MainPagetitle
        mainTitle="Notifications"
        pageTitle="Notifications"
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 col-auto float-end ms-auto ">
            <Link to="" className="btn btn-secondary " onClick={handleShow}>
              Create New
            </Link>
          </div>
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects manage-client">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Notifications</h4>
                    {/* <div>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Export Report
                                            </CSVLink> 
                                        </div> */}
                  </div>
                  <div
                    id="manage-tblwrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="reports-tbl"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>Title</th>
                          <th>Created Date</th>
                          <th>Description</th>
                          <th>Sent To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notificationData?.map((Details, index) => {
                          const serialNumber = (page - 1) * limit + index + 1;
                          return (
                            <tr>
                              <td>{serialNumber}</td>
                              <td className="word-break"><p>{Details?.title}</p></td>
                              <td>
                                {moment(Details?.createdAt).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>
                              <td  className="word-break"><p>{Details?.description}</p></td>
                              <td>
                                {Details?.sent_to == 1
                                  ? "All Pharmacist"
                                  : Details?.sent_to == 2
                                  ? "All Paitent"
                                  : Details?.sent_to == 3
                                  ? "All User"
                                  : "Search User"}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                <button
                                  className="badge-danger light border-0 p-2"
                                  onClick={() => {
                                    setshowd(true);

                                    setNotificationid(Details?._id);
                                  }}
                                >
                                  Delete
                                  {/* {Details.actiondelete} */}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - limit + 1} to{" "}
                        {pagination?.totalCount &&
                        pagination?.totalCount < lastIndex
                          ? pagination?.totalCount
                          : lastIndex}{" "}
                        of {pagination?.totalCount && pagination?.totalCount}{" "}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number?.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                page === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            selectedUsers: "",
            title: "",
            description: "",
            searchUsers: "",
          }}
          onSubmit={async (values) => {
            console.log(values);
            
            
            try {
              const data = await dispatch(
                addNotification({
                  title: values?.title,
                  description: values?.description,
                  sent_to: values?.selectedUsers,
                  user_id: slectedSearchUserId,
                })
              );
              console.log(data);
              if (data?.status == 200) {
                toast.success(data?.data?.message);
                handleClose(false);
                setflag(!flag);
                setUserSearch("")
                console.log("check the value",values);
              } else {
                toast.error(data?.data?.message);
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({ values, handleSubmit, handleChange}) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Add Notification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Select Users</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="selectedUsers"
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === "4") {
                        setShowSearchUser(true);
                      } else {
                        setShowSearchUser(false);
                      }
                    }}
                    value={values?.selectedUsers}
                  >
                    <option>Select Users</option>
                    <option value="1">All Pharmacist</option>
                    <option value="2">All Patient</option>
                    <option value="3">All User</option>
                    <option value="4">Search User</option>
                  </Form.Select>
                  <ErrorMessage name="selectedUsers" component="div" />

                  {searchUser && (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <br />
                      <Form.Label>Search Users</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search Users"
                        name="searchUsers"
                        onChange={(e) => setUserSearch(e.target.value)}
                        value={userSearch}
                      />             

                      {showUserList && (
                        <ul>
                          {UserList?.map((user) => (
                            <li
                              key={user.id}
                              onClick={() => handleUserSelect(user)}
                            >
                              {user.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </Form.Group>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type Here.."
                    autoFocus
                    name="title"
                    onChange={handleChange}
                    value={values?.title}
                  />
                  <ErrorMessage name="title" component="div" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Type Here.."
                    name="description"
                    onChange={handleChange}
                    value={values?.description}
                  />
                  <ErrorMessage name="description" component="div" />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* delete notification */}
      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlerNotificationD}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Notification;
