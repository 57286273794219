import Layout from "../components/layouts/Layout";
import React, { useReducer } from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AirplayIcon from "@mui/icons-material/Airplay";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SchoolIcon from "@mui/icons-material/School";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import TranslateIcon from "@mui/icons-material/Translate";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
/// Image
// import logo from "../../images/user1.png"
import profile from "../../images/profile/profile.png";
import uploadImg from "../../images/profile/profile-placeholder.png";
import MainPagetitle from "../components/layouts/MainPagetitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  deleteUserAcc,
  updateUserProfile,
  viewUserDetail,
} from "../../store/actions/AdminAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";

import Modal from "react-bootstrap/Modal";
import { Button, Switch } from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import { useLocation } from "react-router-dom";
import { getOrderdetails, ordersstatus } from "../../store/actions/AdminAction";
import { Form } from "react-bootstrap";
import { Flag } from "@mui/icons-material";

const validationSchema = yup.object().shape({
  number: yup.string().required(<span style={{ color: "red" }}>Required</span>),
  companyname: yup
    .string()
    .required(<span style={{ color: "red" }}>Required</span>),
});

const ViewOrder = () => {
  const [userdetails, setuserdetails] = useState("");
  const [bill, setbill] = useState([]);
  console.log(bill, "billlllllllllll");
  const [bil, setbil] = useState(null);
  const [flag, setflag] = useState(false);
  const [trackingId, setTrackingId] = useState("");
  const [Company, setCompany] = useState("");
  const [orderdetails, setorderdetails] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  const [show, setshow] = useState(false);
  const [name, setname] = useState(null);
  const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);

  const [showpending, setshowpending] = useState(false);
  const handlePendingClose = () => setshowpending(false);
  const handleshowpending = () => setshowpending(true);

  useEffect(() => {
    const getdetails = async () => {
      try {
        const data = await dispatch(
          getOrderdetails({ order_id: location?.state?.orderid })
        );
        console.log(data);
        if (data?.status == 200) {
          setuserdetails(data?.data?.data?.user);
          setorderdetails(data?.data?.data?.order?.products);
          setbill(data?.data?.data?.order);
          setbil(data?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdetails();
  }, [flag]);

  // const orderdeclinedhandler = async () => {
  //   try {
  //     const data = await dispatch(
  //       ordersstatus({
  //         status: "1",
  //         order_id: location?.state?.orderid,
  //       })
  //     );
  //     console.log(data);
  //     if (data?.status == 200) {
  //       toast.success(data?.data?.message);
  //     } else {
  //       toast.error(data?.data?.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const orderacceptHandler = async () => {
  //   try {
  //     const data = await dispatch(
  //       ordersstatus({
  //         status: "2",
  //         order_id: location?.state?.orderid,
  //       })
  //     );
  //     console.log(data);
  //     if (data?.status == 200) {
  //       toast.success(data?.data?.message);
  //     } else {
  //       toast.error(data?.data?.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const orderstatushandler = async (e, id) => {
    try {
      const modifyData = {
        status: e,
        order_id: location?.state?.orderid,
        tracking_number: bill.tracking_number
          ? bill.tracking_number
          : trackingId,
        courier_company: bill.courier_company ? bill.courier_company : Company,
      };
      if (e == 4 || e == 5 || e == 6) {
        const data = await dispatch(ordersstatus(modifyData));
        console.log(data);
        if (data?.status == 200) {
          toast.success(data?.data?.message);
          setflag(!flag);
          setshow(false);
        } else {
          toast.error(data?.data?.message);
        }
      } else {
        const data = await dispatch(
          ordersstatus({
            status: e,
            order_id: location?.state?.orderid,
          })
        );
        console.log(data);
        if (data?.status == 200) {
          toast.success(data?.data?.message);
          setflag(!flag);
          setshow(false);
        } else {
          toast.error(data?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const obj = {
    0: "Pending",
    1: "Cancel",
    2: "Out of Order",
    3: "Accepted",
    4: "Dispatched",
    5: "Shipped",
    6: "Completed",
  };

  const vatAmount = (bill?.subtotal * 0.02).toFixed(2);

  return (
    <Layout>
      <div>
        <MainPagetitle
          mainTitle="View Order"
          pageTitle="View Order"
          parentTitle="Order Management"
        />
        <div className="container-fluid">
          <Link to="/order-management" className="btn btn-secondary ">
            Back
          </Link>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="profile card card-body px-3 pt-3 pb-0">
                <div className="profile-head">
                  <h4>User Details</h4>

                  <div className="profile-info">
                    <div className="profile-info">
                      <div className="user_detail_img_inner">
                        <img
                          // src={require("../../images/avatar/1.png")}
                          src={
                            userdetails?.main_image
                              ? `https://api.telepharmacare.com/user_docs/${userdetails?.main_image}`
                              : uploadImg
                          }
                          className="img-fluid rounded-circle profile-image"
                          alt="profile"
                        />
                      </div>
                    </div>
                    <div className="card-body d-flex align-items-center">
                      <div className="user-data-view">
                        <h5>{userdetails?.name}</h5>
                        <h5>{userdetails?.email}</h5>
                        <h5>{`${userdetails?.country_code || ""} ${
                          userdetails?.phone_number || ""
                        }`}</h5>
                        {/* <h5 className="mb-0">Lorem Ipsum is simply </h5> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6  col-xxl-6 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Action</h4>
                </div>
                <div className="card-body ">
                  <div className="basic-form">
                    <div className="row ">
                      <div className="col-12 ">
                        <div className="form-check custom-checkbox mb-3 checkbox-success ps-0">
                          {/* <button
                            className="btn btn-success mb-2"
                            onClick={handleShow}
                          >
                            Accept Order
                          </button>
                          <button
                            className="btn btn-danger mb-2 ms-2"
                            onClick={orderdeclinedhandler}
                          >
                            Decline Order
                          </button> */}

                          <select
                            class="form-select"
                            aria-label="Default select example"
                            value={bill?.delivery_status}
                            onChange={(e) => {
                              const selectedValue = Number(e.target.value);
                              setTrackingId(bill?.tracking_number);
                              setCompany(bill?.courier_company);
                              // seti(bill?.order_id);
                              setname(selectedValue);
                              setshow(true);

                              // if (bill?.delivery_status === 6 ) {
                              //   toast.error("This order has already been completed. You cannot change its status.");
                              //   setshow(false);
                              //   return;
                              // }

                              // if (bill?.delivery_status !== 4) {
                              //   if ([0, 1, 2].includes(selectedValue)) {
                              //     setshow(true);
                              //   } else if(selectedValue == 4  || selectedValue == 5) {
                              //     toast.error("Please follow the steps.");
                              //     setshow(false);
                              //   }else if(selectedValue == 6) {
                              //     toast.error("Please follow the steps.");
                              //     setshow(false);
                              //   }
                              // } else {
                              //   // If delivery_status is 4 (Shipped)
                              //   setshow(true);
                              // }
                            }}
                          >
                            <option value="0">Pending</option>
                            <option value="1">Cancel</option>
                            <option value="5">Out of Order</option>
                            <option value="2">Accepted</option>
                            <option value="3">Dispatched</option>
                            <option value="4">Shipped</option>
                            <option value="6">Completed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6  col-xxl-6 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Tracking Details</h4>
                </div>
                <div className="card-body ">
                  <div className="basic-form">
                    <div className="row ">
                      <div className="col-12 ">
                        <div className="form-check custom-checkbox mb-3 checkbox-success ps-0 custom-delivery-details p">
                          <p>Tracking Id : {bill?.tracking_number}</p>
                          <p>Courier Company : {bill?.courier_company}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6  col-xxl-6 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Delivery Details</h4>
                </div>
                <div className="card-body ">
                  <div className="basic-form">
                    <div className="row ">
                      <div className="col-12 ">
                        <div>
                          <div className="form-check custom-checkbox mb-3 checkbox-success ps-0 custom-delivery-details">
                            <p>
                              <span>Name</span> :{" "}
                              {`${bill?.address_id?.first_name} ${bill?.address_id?.last_name}` ||
                                "N/A"}
                            </p>
                            <p>
                              <span>Phone number</span> :{" "}
                              {`${bill?.address_id?.country_code} ${bill?.address_id?.phone_number}` ||
                                "N/A"}
                            </p>
                            <p>
                              <span>Address</span> :{" "}
                              {bill?.address_id?.street_number || "N/A"}
                            </p>
                            <p>
                              <span>Postal code</span> :{" "}
                              {bill?.address_id?.postal_code || "N/A"}
                            </p>
                            <p><span>City</span> : {bill?.address_id?.city || "N/A"}</p>
                            <p>
                             <span>Country</span>  : {bill?.address_id?.country || "N/A"}
                            </p>
                            <p>
                            <span>Security Code Building</span>  :{" "}
                              {bill?.address_id?.secuirty_code || "N/A"}
                            </p>
                            <p>
                            <span>C/O Address</span>   :{" "}
                              {bill?.address_id?.apartment_number || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="profile card card-body px-3 pt-3 pb-3">
                <div className="profile-head">
                  <h4>Order Details</h4>
                  {orderdetails?.map((details) => {
                    console.log(details, "");
                    return (
                      <>
                        <div className="order-box  mb-3">
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="profile-info p-0">
                                <div className="product-img">
                                  {details?.product_id?.productimage &&
                                    details?.product_id?.productimage[0] && (
                                      <img
                                        className="img-fluid"
                                        src={`https://api.telepharmacare.com/public/production/${details.product_id.productimage[0]}`}
                                        alt="Product Image"
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 d-flex align-items-center">
                              <div className="card-body  p-0">
                                <div className="user-data-view">
                                  <h4>{details?.product_id?.title}</h4>
                                  <p className="mb-0">
                                    {details?.product_id?.product_description}
                                  </p>
                                  <h4>${details?.product_id?.price}</h4>
                                  <h5 style={{ textAlign: "right" }}>
                                    {details?.quantity}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* )
                                })} */}
                      </>
                    );
                  })}

                  {/* {bill?.map((data) => {
                    console.log(data);
                    return ( */}
                  <div className="order-total">
                    <div className="total-inner">
                      <h3> Sub Total</h3>
                      <h4>${bill?.subtotal}</h4>
                    </div>
                    <div className="total-inner">
                      <h3>Discount</h3>
                      <h4>${bil?.discount}</h4>
                    </div>
                    <div className="total-inner">
                      <h3>Shipping Fee</h3>
                      <h4>${bil?.shipping_charges}</h4>
                    </div>
                    <div className="total-inner pb-2">
                      <h3>VAT</h3>
                      <h4>{vatAmount}</h4>
                    </div>
                    <div className="total-inner sub">
                      <h3>Total</h3>
                      <h4>${bill?.total_amount}</h4>
                    </div>
                  </div>
                  {/* );
                  })} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {name == "0" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Pending Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(0)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "3" ? (
        <Modal show={show} onHide={handleClose}>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ number: "", companyname: "" }}
            onSubmit={async (values) => {
              console.log(values);
              try {
                const data = await dispatch(
                  ordersstatus({
                    status: "3",
                    order_id: location?.state?.orderid,
                    tracking_number: values?.number,
                    courier_company: values?.companyname,
                  })
                );
                console.log(data);
                if (data?.status == 200) {
                  toast.success(data?.data?.message);
                  handleClose(false);
                  setflag(!flag);
                  setTrackingId(values?.number);
                  setCompany(values?.companyname);
                } else {
                  toast.error(data?.data?.message);
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Dispatched status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Tracking ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Here.."
                      name="number"
                      as={Form.Control}
                      onChange={handleChange}
                      value={values?.number}
                    />
                    <ErrorMessage name="number" component="div" />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Courier Company</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Here.."
                      name="companyname"
                      as={Form.Control}
                      onChange={handleChange}
                      value={values?.companyname}
                    />
                    <ErrorMessage name="companyname" component="div" />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="button-add"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-add"
                    variant="secondary"
                    type="button"
                    onClick={() => {
                      // orderAccpthandler();
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : name == "1" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Cancel Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(1)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "5" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Out of Order Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(5)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "2" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Accepted Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(2)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "4" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Shipped Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(4)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : name == "6" ? (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> Completed Status </Modal.Title>
          </Modal.Header>
          <Modal.Body>Are You Sure About This?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="danger" onClick={() => orderstatushandler(6)}>
              yes
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default ViewOrder;
